<template>
    <div class="portfolio-item instructions" :class="{ active: active }">
        
    </div>
</template>

<script>
export default {
    props: ['active'],
    mounted(){

    }
}
</script>

<style>

.instructions {
    background-image: url('../../assets/clickdraginst.svg');
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
}

</style>