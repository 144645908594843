<template>
<!-- :slides-per-view="content.length" -->
    <div class="portfolio-wrapper" ref="carouselWrapper" @click="test">
        <Swiper
            :centeredSlides="true"
            :watchSlidesVisibility="true"
            navigation
            :pagination="{ clickable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :breakpoints="{
                2000: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                1200: {
                    slidesPerView: 1.5,
                    spaceBetween: 10
                },
                920: {
                    slidersPerView: 1,
                    spaceBetween: 10
                }
            }"
        >
            <SwiperSlide v-slot="{ isActive }">
                <PortfolioHowTo :active="isActive" />
            </SwiperSlide>
            <SwiperSlide v-for="(item, index) in content" :key="'item-'+index" v-slot="{ isActive }">
                
                <PortfolioItem
                    :title="item.title"
                    :details="item.details"
                    :image="item.image"
                    :orientation="item.orientation"
                    ref="pItem"
                    :isBefore="(index === active-1)"
                    :isAfter="(index === active+1)"
                    :active="isActive"
                    container="carousel"
                    :links="{ link: item.link, github: item.github }" 
                    :focus="item.focus"
                    />
            </SwiperSlide>
        </Swiper>
    </div>

</template>

<script>
import PortfolioItem from './PortfolioItem'
import PortfolioHowTo from './PortfolioHowTo'
import Content from '../../fixtures/portfolio.json'
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Navigation, Pagination, A11y]);

export default {
    data(){
        return {
            active: 2,
            content: Content,
            offset: 0,
            doit: null
        }
    },
    components: {
        PortfolioItem,
        Swiper,
        SwiperSlide,
        PortfolioHowTo
    },
    methods: {
        onSwiper(swiper) {
            console.log(swiper);
        },
        onSlideChange() {
            console.log('slide change');
        },
        test(){
            console.log('hello')
        }
    },
    watch: {
        active: function(){
            
        }
    }
}
</script>

<style>

@import '../../css/swiper.css';

.swiper-slide {
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-slide-visible {
  opacity: 1;
}

.swiper-wrapper {
    margin: 0 auto;
}

.swiper-container {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    overflow: hidden;
}

.swiper-slide-next, .swiper-slide-prev {
    pointer-events: none;
}

.swiper-button-prev, .swiper-button-next {
    color: #252525;
    background: #ffffff;
    border-radius: 50%;
    margin: -9% 1rem 0px 2rem;
    width: 70px;
    height: 70px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
    transition: 0.6s;
}

.swiper-button-prev:focus, .swiper-button-next:focus {
    border: none;
    outline: none;
}

.swiper-button-prev:hover, .swiper-button-next:hover {
    transform: scale(1.1);
    color: #ff9505;
}

.swiper-pagination {
    position: relative;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem 0px;
}


.swiper-pagination-bullet {
    background: none;
    width: 10px;
    height: 10px;
    border: 2px solid #f4f4f4;
    opacity: 0.8;
    outline: none;
    border-radius: 50%;
}

.swiper-pagination-bullet-active {
    background: #f4f4f4;
    border: 2px solid #f4f4f4;
    opacity: 1;
    transform: scale(1.4);
    transition: 0.6s;
    box-shadow: 0px 0px 5px 0px white;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 10px;
}


.portfolio-carousel {
    min-width: 100%;
    min-height: 720px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: 1s;
}

.portfolio-carousel * {
    flex-shrink: 0;
    margin: 0px
}


@media screen and (max-width: 950px){
    /* .portfolio-content-wrapper {
        height: 100vh; 
        height: calc(var(--vh, 1vh) * 100);
    } */
    .portfolio-wrapper {
        /* position: sticky; */
        /* top: 5px; */
    }

    .swiper-container {
        height: 90vh;
    }



}

@media screen and (max-width: 1150px){

    .swiper-button-prev, .swiper-button-next {
        display: none;
    }

}


</style>