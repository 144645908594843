<template>
    <div class="about-breakdown">
        <h1 class="section-header">Tech I Use</h1>
        <h3>LANGUAGES</h3>
        <div class="row">
            <div class="item">
                <img src="../../assets/JS-logo.svg">
                JS
            </div>
            <div class="item">
                <img src="../../assets/Swift-icon.svg">
                Swift
            </div>
            <div class="item">
                <img src="../../assets/PHP-logo.svg">
                PHP
            </div>
            <div class="item">
                <img src="../../assets/HTML-logo.svg">
                HTML
            </div>
            <div class="item">
                <img src="../../assets/CSS-logo.svg">
                CSS
            </div>
        </div>
        <h3>SOFTWARE</h3>
        <div class="row">
            <div class="item">
                <img src="../../assets/Photoshop-icon.svg">
                Ps
            </div>
            <div class="item">
                <img src="../../assets/Illustrator-icon.svg">
                Ai
            </div>
            <div class="item">
                <img src="../../assets/Xcode-icon.svg">
                XCode
            </div>
            <div class="item">
                <img src="../../assets/VSCode-icon.svg">
                VSCode
            </div>
        </div>
        <h3>FRAMEWORKS</h3>
        <div class="row">
            <div class="item">
                <img src="../../assets/Vue-icon.svg">
                Vue
            </div>
            <div class="item">
                <img src="../../assets/React-icon.svg">
                React
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.about-breakdown {
    max-width: 1000px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    color: #f4f4f4;
    display: flex;
    flex-direction: column;
}

h1 {
    margin: 1rem;
}

h3 {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: -15px;
    opacity: 0.3;
}

.about-breakdown .row {
    justify-content: center;
    margin: 0.8rem 0px 1.4rem 0px;
}

.about-breakdown .item {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.6rem;
}

.about-breakdown .item img {
    width: 90px;
    margin-bottom: 5px;
    transition: transform 0.4s ease-in-out;
}

.about-breakdown .item:hover > img {
    transform: scale(1.1)
}

.about-breakdown .item:hover {
    color: #00d0cb;
}

@media screen and (max-width: 650px){
    .about-breakdown .row {
        flex-wrap: wrap;
    }
}


</style>