<template>

    <div id="name-backgrounds">
        <div class="letter-wrapper">
            <div class="name-letter" id="name-o2"></div>
            <div class="name-letter" id="name-n"></div>
            <div class="name-letter" id="name-h"></div>
            <div class="name-letter" id="name-o"></div>
            <div class="name-letter" id="name-j"></div>
            <div class="letter-controllers">
            </div>
        </div>
        <div class="letter-buttons">

        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style scoped>

    #name-backgrounds {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        pointer-events: none;
        filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.2));
        /* Thanks Thacchi :D */
    }

    .letter-wrapper {
        position: relative;
        height: 97%;
    }

    .name-letter {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 83%;
        background-origin: padding-box;
        transition: 1s;
    }


    #name-j {
        background-image: url("../../assets/name_j.svg");
    }

    #name-o {
        background-image: url("../../assets/name_o.svg");
    }

    #name-h {
        background-image: url("../../assets/name_h.svg");
    }

    #name-n {
        background-image: url("../../assets/name_n.svg")
    }

    #name-o2 {
        background-image: url("../../assets/name_o2.svg");
    }

    @media screen and (max-width: 1100px){
        #name-backgrounds {
            width: 75%;
        }
    }

    @media screen and (max-width: 850px){
        #name-backgrounds {
            width: 105%;
            margin-left: -15%;
        }
    }

</style>