<template>
    <div class="outcome" v-if="status === 'success'">
        <img src="../../assets/emailSuccess.png" alt="A mail box with a thumbs up hand poking out">
        <div class="msg">
            <h1>SUCCESS!</h1>
            <h2>
                <span>
                    YOUR EMAIL HAS BEEN SENT
                </span>
            </h2>
        </div>
    </div>
    <div class="outcome" v-else>
        <img src="../../assets/emailFail.png" alt="A broken mail box to symbolise the failed email">
        <div class="msg">
            <h1>OH NO!</h1>
            <h2>
                <span>
                    SOMETHING WENT WRONG...
                </span>
            </h2>
        </div>
    </div>
</template>

<script>
export default {
    props: ['status']
}
</script>

<style>

.outcome {
    text-align: center;
}

.outcome .msg {
    
}

.outcome h1 {
    font-size: 5rem;
    margin: 10px auto 0px auto;
    line-height: 100%;
    font-weight: 600;
    color: black;
}
/* 
@supports (-webkit-text-stroke: 5px black) {
  .outcome h1 {
    -webkit-text-stroke: 5px black;
    -webkit-text-fill-color: white;
  }
} */

.outcome .msg h2 span {
    z-index: 2;
    color: #000;
    background: white;
    padding: 2px 20px;
    /* border: 5px solid white; */
    font-weight: 600;
    letter-spacing: 0.04rem;
}

.outcome img {
    max-width: 500px;
    width: calc(100% - 6rem);
    object-fit: cover;
    object-position: top;
    z-index: 1;
}

</style>