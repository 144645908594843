<template>
    <div class="projected-view row">
        <div class="codepen" @mouseover="jsfiddleHover = true" @mouseout="jsfiddleHover = false">
            <a href="https://codepen.io/johno-m" target="blank">
                <img src="../assets/codepen.svg" alt="Codepen's logo hovering at the side" v-if="!jsfiddleHover">
                <img src="../assets/codepen_h.svg" alt="Codepen's logo hovering at the side" v-else>
            </a>
        </div>
        <div class="github" @mouseover="githubHover = true" @mouseout="githubHover = false">
            <a href="https://github.com/johno-m/" target="blank">
                <img src="../assets/github.svg" alt="Github logo hovering at the side" v-if="!githubHover">
                <img src="../assets/github_h.svg" alt="Github logo hovering at the side" v-else>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            githubHover: false,
            jsfiddleHover: false
        }
    }
}
</script>

<style>

.codepen, .github {
    flex: 1;
}

.codepen img {
    width: 54%;
    margin: 30% 20% 0 26%;
}

.github img {
    width: 68%;
    margin: 81% 16% 0 10%;
}

.projected-view {
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>