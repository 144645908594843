<template>
    <div id="app" ref="app">
        <!-- <toolbar></toolbar> -->
        <PageMain />
        <PagePortfolio />
        <PageAbout />
        <PageContact />
        <PageFooter />
        <!-- <SidebarScroller /> -->
    </div>
</template>

<script>
// import Toolbar from './views/Toolbar.vue'
import PageMain from './views/Page-Main.vue'
import PageAbout from './views/Page-About.vue'
import PageContact from './views/Page-Contact.vue'
import PagePortfolio from './views/Page-Portfolio.vue'
import PageFooter from './views/Page-Footer.vue'
// import SidebarScroller from './components/SidebarScroller'

export default {
    name: 'app',
    data () {
        return {
            resizeEvent: null
        }
    },
    methods: {
        setMobileHeight(){
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    },
    mounted(){
        if(this.resizeEvent) {
            removeEventListener(this.resizeEvent)
        }
        this.resizeEvent = window.addEventListener('resize', this.setMobileHeight)
    },
    components: {
        // Toolbar,
        PageMain,
        PageAbout,
        PageContact,
        PagePortfolio,
        PageFooter,
        // SidebarScroller
    }

}
</script>

<style>

@import './css/jm.css';
@import './css/style.css';


.container {
    max-width: 1300px;
    margin: 0px auto;
}

#app {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100px;
}

</style>
