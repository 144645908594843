<template>
    <section>
        <div class="main-screen" ref="mainscreen">
            <div class="background"></div>
            <div class="content">
                <div class="left">
                    <div class="palmTree">
                        <div class="palmTreeMover" ref="palmtree">
                            <img src="../assets/palmTree.svg" alt="">
                        </div>
                    </div>
                    <!-- <nav class="menu col ar">
                        <div class="menu-spacer"></div>

                            <button class="menu-btn about-btn"></button>
                            <button class="menu-btn contact-btn"></button>
                            <button class="menu-btn projects-btn"></button>
                      
                        
                    </nav> -->
                    
                    <Menu />
                    <div>         
                        <div ref="cloud1" class="cloud2 cloud">
                            <img src="../assets/cloud2.svg" alt="">
                        </div>
                        <div ref="cloud2" class="cloud1 cloud">
                            <img src="../assets/cloud1.svg" alt="">
                        </div>
                    </div>
                    <NameLetters />
                    
                    
                </div>
                
                <div class="right">
                    <MainViewRight />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import MainViewRight from './View-Main-Right';
import NameLetters from '../components/Main/NameLetters'
import Menu from '../components/Main/Menu'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    data(){
        return {
            
        }
    },
    methods: {
        animateClouds(){
            this.animateCloud(this.$refs.cloud1, (20 * Math.cos(25 * Math.PI/180)), (20 * Math.sin(25 * Math.PI/180)));
            this.animateCloud(this.$refs.cloud2, (30 * Math.cos(25 * Math.PI/180)), (30 * Math.sin(25 * Math.PI/180)));
        },
        animateCloud(cloud, toX, toY){
            let duration = Math.floor((Math.random() * 3000) + 8000);
            cloud.animate([
            // keyframes
            { transform: 'translate(-'+toX+'px, '+toY+'px)' }, 
            { transform: 'translate(0px, 0px)' }, 
            { transform: 'translate(-'+toX+'px, '+toY+'px)' }
            ], { 
            // timing options
            duration: duration,
            easing: "ease-in",
            iterations: Infinity
            });
        },
        scrollAnimation() {
            gsap.timeline({
                scrollTrigger: {
                    trigger: this.$refs.mainscreen,
                    start: "top top",
                    end: "50% 20%",
                    scrub: 2,
                    markers: false,
                    pin: false
                }
            })
            // .to(this.$parent.$refs.app, { backgroundColor: '#c45e38' })
            .to(this.$refs.palmtree, { y: '100px'})
        },
        scrollTo(target){
            console.log(target)
        }
    },
    components: {
        MainViewRight,
        NameLetters,
        Menu
    },
    mounted(){
        this.animateClouds();
        this.scrollAnimation();
    }
}
</script>

<style>

    .main-screen {
        display: flex;
        position: relative;
        width: 100vw; 
        height: 56.25vw;
        max-height: 100vh;
        max-width: 177.78vh;
        margin: 0 auto;
        overflow: hidden;
    }

    .palmTree {
        width: 32%;
        position: absolute;
        left: 55%;
        top: 16%;
        overflow: hidden;
        height: 50%;
    }

    .palmTreeMover {
        position: relative;
    }
    
    .background {
        width: 100%;
        padding-bottom: 65%;
        top: 0px;
        left: 0px;
    }

    .content {
        display: flex;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    .left, .right {
        flex: 1;
        position: relative;
    }

    .left {
        
    }

    .right {
        /* clip-path: polygon(0 0, 0% 68.0%, 89.5% 100%, 100% 100%, 100% 0%); */
    }

    .name {
        position: absolute;
        height: 55%;
        width: 100%;
    }

    .name img {
        width: 100%;
    }

    .menu {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .menu-spacer {
        height: 30%;
        width: 100%;
    }

    .end {
        /* transition: 1s; */
        opacity: 0;
        width: 5%;
        background-color: white;
        height: 59%;
        position: absolute;
        top: 7%;
        left: 103%;
        margin-left: 4%;
    }

    .projected-view {
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .cell {
        margin: 4%;
    }

    .cloud {
        position: absolute;
    }

    .cloud1 {
        width: 31%;
        left: 48%;
        top: 5%;
    }

    .cloud2 {
        width: 21%;
        left: 14%;
        top: 17%;
    }

    .screenwipe-enter {
        transform: translateY(100%);
    }

    .screenwipe-enter-active {
        animation: screenWipeIn 1s;
    }

    



    @keyframes screenWipeIn {
    0% { 
            transform: translateY(100%);
        }
    100% { 
            transform: translateY(0);
        }
    }


    .screenwipe-leave {
        transform: translateY(0);
    }

    .screenwipe-leave-active {
        animation: screenWipeOut 1s;
    }

    @keyframes screenWipeOut {
    0% { 
            transform: translateY(0);
        }
    100% { 
            transform: translateY(-100%);
        }
    }

    @media screen and (max-width: 1100px){
        .left .menu {
            display: none;
        }
        .left {

        }
        .right {
            display: none;
        }

        .cloud1 { 
            width: 21%;
            left: 27%;
            top: 1%;
        }
        .cloud2 {
            width: 14%;
            left: 8%;
            top: 7%;
        }
        .palmTree {     
            width: 24%;
            position: absolute;
            left: 34%;
            top: 15%;
            overflow: hidden;
            height: 50%;
        }
        .main-screen {
            width: 100vw; 
            height: 100vw;

        }

        #name-backgrounds {
            margin-top: -10%;
        }

    }

    @media screen and (max-width: 850px){
        
        
        .left .menu {
            display: none;
        }
        .left {

        }
        .right {
            display: none;
        }

        .cloud1 { 
            width: 25%;
            left: 27%;
            top: 1%;
        }
        .cloud2 {
            width: 18%;
            left: 8%;
            top: 7%;
        }
        .palmTree {     
            width: 29%;
            position: absolute;
            left: 40%;
            top: 2%;
            overflow: hidden;
            height: 60%;
        }
        #name-backgrounds {
            margin-top: 0%;
        }
    }

</style>