<template>
    <div class="about-me">
        <div class="info">
            <h1>About Me</h1>
            <span data-v-574953f2="" class="bio">My name's <span data-v-574953f2="" class="col1">John</span></span>
            <span data-v-574953f2="" class="bio">I live in <span data-v-574953f2="" class="col2">Norwich, UK</span></span>
            <span data-v-574953f2="" class="bio">I like to <span data-v-574953f2="" class="col3">code</span> and make <span data-v-574953f2="" class="col3">music</span></span>
        </div>
        <div class="image">
            <img src="../../assets/portrait.svg" alt="A vector drawing of me... John">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.about-me {
    display: flex;
    flex-direction: row;
    max-width: 900px;
    margin: 6rem auto;
    color: #f4f4f4;
}

.about-me h1 {
    font-family: Lora,serif;
    padding-bottom: 6px;
    font-size: 5rem;
    letter-spacing: .06rem;
}

.info {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    padding-top: 3rem;
    flex: 1;
}

.image {
    flex: 1;
}



@media screen and (max-width: 920px){

    .about-me {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    
    .image {
        width: 100%;
        text-align: center;
    }

    .image img {
        width: 60%;
    }

    .about-me h1 {
        text-align: center;
        font-size: 3.5rem;
    }

    .about-me .info {
        padding: 2rem;
        font-size: 1.6rem;
    }

}

</style>