<template>
    <section class="footer col">
        <span class="tag">Made by Johno</span>
        <span class="links">
            <a href="https://github.com/johno-m/" target="blank">
                <img src="../assets/github-icon.svg" width="55"/>
            </a>
            <a href="https://codepen.io/johno-m" target="blank">
                <img src="../assets/codepen-icon.svg" width="55"/>
            </a>
        </span>
    </section>
</template>

<script>
export default {
    data(){
        return {
            
        }
    }
}
</script>

<style scoped>

.links img {
    margin: 10px;
}

.links img:hover {
   filter: sepia(100%) saturate(300%) brightness(70%) hue-rotate(130deg);
}

.tag {
    margin: 30px 0px 0px 0px;
    text-transform: uppercase;
    font-weight: 600;
}

.footer {
    min-height: 100px;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f4f4f4;
    padding: 2rem;
}

</style>