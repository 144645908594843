<template>
    <div class="details col">
        <slot></slot>
    </div>
</template>

<script>

export default {
    data(){
        return {
            
        }
    },
    mounted(){
        console.log(this.$refs)
    }
}
</script>

<style scoped>

.details { 
    color: #f8f8f8;
    font-family: 'Lato', sans-serif;
    text-align: right;
    width: 40%;
    padding: 2.5rem 2.5rem 2.5rem 0rem;
    font-size: 1.2rem;
    letter-spacing: 0.07rem;
    white-space: pre-line;
}

.details h2 {
    text-align: right;
    font-size: 3rem;
    margin: 0px;
    font-family: 'Lora', serif;
}

span {
    line-height: 1.7rem;
    margin-top: 1rem;
}

.focusText {
    color: #ff9505;
    flex: 1;
    padding-top: 1rem;
}

@media screen and (max-width: 950px){
    .details { 
        width: 100%;
        text-align: left;
        padding: 1rem 2rem;
        font-size: 1.1rem;
        overflow: hidden;
    }
    .details h2 {
        text-align: left;
        font-size: 2.5rem;
    }
    .details span {
        margin-top: 0px;
        overflow: hidden;
    }
}


@media screen and (max-width: 560px){
    .details h2 {
        text-align: left;
        font-size: 2.2rem;
    }
}



@media screen and (max-width: 350px){

    .details h2 {
        text-align: left;
        font-size: 1.5rem;
    }
    .details { 
        width: 100%;
        text-align: left;
        padding: 1rem 2rem;
        font-size: 0.9rem;
        line-height: 1rem;
        overflow: hidden;
    }

    .details span {
        margin-top: 0px;
        overflow: hidden;
        line-height: 1rem;
    }
}

</style>