<template>
    <nav class="menu2">
        <div class="about" @click="scrollTo('About')">
            <img src="../../assets/about2.svg" alt="">
        </div>
        <div class="contact" @click="scrollTo('Contact')">
            <div class="contact-wrapper">
                <div class="contact-mask"></div>
                <img src="../../assets/contact3.svg" alt="">
            </div>
        </div>
        <div class="projects" @click="scrollTo('Portfolio')">
            <img src="../../assets/projects2.svg" alt="">
        </div>
    </nav>
</template>

<script>
export default {
    methods: {
        scrollTo(target){
            this.emitter.emit('scrollTo'+target)
        }
    }
}
</script>

<style>

    .menu2 {
        position: absolute;
        width: 84%;
        top: 57%;
        left: 20%;
        z-index: 1;
        display: grid;
        grid-template-areas:
            "projects about"
            "projects contact";
        grid-template-columns: 6fr 3fr;
    }

    .menu2 .projects {
        grid-area: projects;
        margin-top: 26%;
        margin-right: 7%;
        transition: 0.6s;
        z-index: 3;
        cursor: pointer;
    }

    .menu2 .about {    
        grid-area: about;
        margin-left: -52%;
        margin-top: -6%;
        transition: 0.6s;
        cursor: pointer;
        
    }

    .menu2 .contact {
        grid-area: contact;
        position: relative;
        margin-top: -2%;
        margin-left: -10%;
        cursor: pointer;
    }

    .menu2 .contact .contact-wrapper {
        overflow: hidden;
    }

    .menu2 .contact img {
        z-index: 3;
        transition: 0.6s;
        display: block;
    }

    .menu2 .contact .contact-mask {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: url('../../assets/contactMask.svg');
        background-repeat: no-repeat;
        z-index: 5;
        pointer-events: none;
        transition-delay: 0.6s;
    }

    .menu2 .projects:hover, .menu2 .about:hover {
        transform: translate(10px, 6px);
    }

    .menu2 .contact:hover .contact-mask {
        transition-delay: 0s;
        opacity: 1;
    }

    .menu2 .contact img:hover {
        transform: translate(0px, 6px);
    }

    .menu2 div {
        
        
    }


    @media screen and (max-width: 1100px){
        
        .menu2 {
            width: 65%;
            top: 52%;
            left: 16%;
        }

    }

    @media screen and (max-width: 850px){
        
        .menu2 {        
            width: 85%;
            top: 51%;
            left: 8%;
        }

    }

</style>