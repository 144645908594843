<template>
    <section class="about-me" ref="about">
        <AboutMain />
        <AboutBreakdown />
    </section>
</template>

<script>
import AboutBreakdown from '../components/About/AboutBreakdown'
import AboutMain from '../components/About/AboutMain'
export default {
    data(){
        return {
            
        }
    },
    mounted(){
        this.emitter.on("scrollToAbout", () => {
            window.scroll({ top: this.$refs.about.offsetTop, behavior: 'smooth' });
        })
    },
    components: {
        AboutMain,
        AboutBreakdown
    }
}
</script>

<style scoped>


</style>