<template>
    <div class="links">
        <a :href="links.github" v-if="links.github">
            <img src="../../assets/github-icon.svg" alt="Link to Github Repo" width="50">
        </a>
        <a :href="links.link" v-if="links.link">
            <img src="../../assets/link.svg" alt="Link to the Project" height="50">
        </a>
    </div>
</template>

<script>
export default {
    props: ['links']
}
</script>

<style scoped>

.links {
    text-align: center;
    margin-top: 2rem;
}

.links img {
    margin: 0 15px;
    transition: 0.6s ease-in-out;
}

.links img:hover {
    transform: scale(1.11)
}

</style>