<template>
    <section class="contact" ref="contact">
        <h1 class="section-header">Contact</h1>
        <div class="email-address">
            <img src="../assets/address.png" alt="mail @ johnomckay . dev" >
        </div>
        <div class="card">
            <div class="error-msg" v-if="error">{{error}}</div>
            <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in">
            <form action="#" v-on:submit.prevent v-if="status === 'form'">
                <div class="inputs">
                    <div class="first col">
                        <div class="col">
                            <label :class="labelClass('name')" for="name">{{labels.values.name}}</label>
                            <input :class="inputClass('name')" type="text" name="name" id="name" v-model="form.name" @input="indicateError($event, 'incomplete')" @blur="indicateError($event, 'complete')">
                        </div>
                        <div class="col">
                            <label :class="labelClass('email')" for="email">{{labels.values.email}}</label>
                            <input :class="inputClass('email')" type="email" name="email" id="email" v-model="form.email" @input="indicateError($event, 'incomplete')" @blur="indicateError($event, 'complete')">
                        </div>
                    </div>
                    <div class="second col">
                        <label :class="labelClass('msg')" for="message">{{labels.values.msg}}</label>
                        <textarea :class="inputClass('msg')" name="message" id="message" v-model="form.msg" @input="indicateError($event, 'incomplete')" @blur="indicateError($event, 'complete')"></textarea>
                    </div>
                </div>
                <button @click="sendEmail" :disabled="!validated">{{btnText}}</button>
            </form>
            <ContactOutcome :status="status" v-else />
            </transition>
        </div>
    </section>
</template>

<script>
import ContactOutcome from '../components/Contact/ContactOutcome'

export default {
    data(){
        return {
            form: {
                name: '',
                email: '',
                msg: ''
            },
            formValidated: {
                name: false,
                email: false,
                msg: false
            },
            validated: false,
            error: null,
            status: 'form',
            btnText: 'SEND',
            labels: {
                values: {
                    name: 'Your Name',
                    email: 'Your Email',
                    msg: 'Message',
                },
                defaults: {
                    name: 'Your Name',
                    email: 'Your Email',
                    msg: 'Message',
                }
            }
        }
    },
    methods: {
        checkForm(){
            if(this.form.name.length > 3 && this.form.msg.length > 10 && this.validateEmail(this.form.email)){
                this.validated = true
            } else {
                this.validated = false
            }
        },
        sendEmail(){
            if(this.validated){
                this.btnText = 'SENDING'
                const formData = new FormData()
                for ( var key in this.form ) {
                    formData.append(key, this.form[key]);
                }
                fetch("https://secure107.prositehosting.co.uk/php/sendEmail.php", { method: 'POST', body: formData })
                    .then(res => res.json())
                    .then(data => {
                        this.status = data
                    })
                    .catch(() => {
                        this.error = "Something has gone wrong... Please email at mail@johnomckay.dev"
                    })
            } else {
                this.error = "All inputs need to be filled before you can send"
            }
            
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        indicateError(e, state){
            const elemName = e.target.name
            if(elemName === 'name'){
                if(this.form.name.length < 3){
                    if(state === 'complete' && this.form.name.length > 0){
                        this.labels.values.name = `Are you sure that's your name?`
                        this.formValidated[elemName] = 'invalid'
                    }
                } else {
                    this.labels.values.name = this.labels.defaults.name
                    this.formValidated[elemName] = true
                }
            } else if(elemName === 'email'){
                if(!this.validateEmail(this.form.email)){
                    if(state === 'complete' && this.form.email.length > 0){
                        this.labels.values.email = `That email doesn't look right...`
                        this.formValidated[elemName] = 'invalid'
                    }
                } else {
                    this.formValidated[elemName] = true
                    this.labels.values.email = this.labels.defaults.email
                }
            } else if(elemName === 'message'){
                if(this.form.msg.length < 10){
                    if(state === 'complete' && this.form.msg.length > 0){
                        this.labels.values.msg = `That is a very short message...`
                        this.formValidated.msg = 'invalid'
                    }
                } else {
                    this.formValidated.msg = true
                    this.labels.values.msg = this.labels.defaults.msg
                }
            }
            this.isValidated()
        },
        labelClass(elem){
            console.log(elem)
            if(this.labels.values[elem] === this.labels.defaults[elem]){
                return null
            } else {
                return {
                    wrong: true
                }
            }
        },
        inputClass(elem){
            if(this.formValidated[elem] === true){
                return {
                    valid: true 
                }
            } else if(this.formValidated[elem] === 'invalid') {
                return {
                    invalid: true 
                }
            }
        },
        isValidated(){
            if(this.formValidated.name === true && this.formValidated.email === true && this.formValidated.msg){
                this.validated =  true
            } else {
                this.validated = false
            }
        }
    },
    mounted() {
        this.emitter.on("scrollToContact", () => {
            window.scroll({ top: this.$refs.contact.offsetTop, behavior: 'smooth' });
        })
    },
    components: {
        ContactOutcome
    }
}
</script>

<style scoped >

.contact {
    margin: 3rem auto 0px auto;
    
}

.contact .card {
    max-width: 900px;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    margin: auto;
    border-radius: 2rem;
    padding: 4rem;
}

.first {
    flex: 1;
}

.second {
    flex: 2;
    margin-left: 3rem;
}

.inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.email-address {
    text-align: center;
    margin: 0 auto 20px;
}


.email-address img {
    width: 140px;
}

.wrong {
    color: red;
}

.valid {
    background: #8ff6cf;
    border: 1px solid #8ff6cf;
}

.invalid {
    background: #ffd6d6;
    border: 1px solid #ffd6d6;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

label {
    font-size: .9rem;
    letter-spacing: .02rem;
    width: 100%;
    padding: 0 0 12px 0;
    color: #666;
}

input {
    width: 100%;
    outline: none;
    padding: 1rem 1rem;
    font-size: 1rem;
    margin-bottom: 25px;
    letter-spacing: .06rem;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #cecece;
    transition: .6s;
}

textarea {
    outline: none;
    border: 1px solid #cecece;
    letter-spacing: .06rem;
    border-radius: 8px;
    width: 100%;
    height: 300px;
    resize: none;
    box-sizing: border-box;
    padding: 12px;
    font-family: Lato,sans-serif;
    font-size: 1rem;
    line-height: 1.6rem;
    transition: .6s;
}

button {
    padding: 1rem 2.6rem;
    font-size: 1.1rem;
    letter-spacing: .06rem;
    border-radius: 8px;
    margin: 3rem 0 -1rem 0;
    background: #00d4cc;
    color: #fff;
    transition: .6s;
}

button:disabled {
    opacity: 0.3;
}

input:hover, textarea:hover, input:focus, textarea:focus  {
    box-shadow: 0px 0px 8px 2px rgba(146, 237, 199, 1);
    border: 1px solid #ffffff;
}

.error-msg {
    padding: 1rem;
    background: #ffaeae;
    margin-bottom: 2rem;
    border-radius: 10px;
    border: 2px solid red;
    color: red;
    text-align: center;
    font-size: 0.9rem;
}

@media screen and (max-width: 920px){
    .inputs {
        flex-direction: column;
    }   
    .second {
        flex: 1;
        margin-left: 0rem;
    }
    .contact .card {
        margin: 1rem;
        width: calc(100% - 2rem);
    }
}

@media screen and (max-width:500px){
    .contact .card {
        padding: 3rem;
    }
}

</style>