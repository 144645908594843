<template>
    <div class="preview">
        <div class="img-window" :style="{backgroundImage: `url(${imageSrc})`}">
            <a :href="link.link ? link.link : link.github" alt="Click here to see this project in the wild" target="blank">
            <img 
                :src="imageSrc" 
                alt="Default image placeholder" 
                draggable="false"
                :class="classList"
                />
                </a>
        </div>
    </div>
</template>

<script>

export default {
    props: ['image', 'orientation', 'link', 'github'],
    data(){
        return {
            
        }
    },
    methods: {
        
    },
    computed: {
        classList: function() {
            return {
                [`pan-${this.orientation}`]: true
            }
        },
        imageSrc: function(){
            if(this.image.includes('/')){
                return this.image
            } else {
                return `./assets/previews/${this.image}`
            }
        }
    }
}
</script>

<style scoped>
    .preview {
        width: 60%;
        padding-bottom: 80%;
        position: relative;
    }

    .img-window {
        position: absolute;
        margin: 3rem;
        width: calc(100% - 6rem);
        height: calc(100% - 6rem);
        top: 0px;
        left: 0px;
        background-size: cover;
        font-size: 0.9rem;
        color: #f4f4f4;
        font-family: 'Lato';
    }

    .img-window img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .img-window video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background: #252525;
    }

    .controls {
        margin: 4rem;
        width: calc(100% - 8rem);
        height: calc(100% - 8rem);
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .controls:hover > .btn {
        opacity: 1;
    }

    .btn {
        border-radius: 50%;
        transition: 0.5s;
        opacity: 0;
        color: white;
    }

    .btn:hover {
        border-radius: 50%;
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3);
        color:#ffbb00;
    }

    .pan-narrow, .pan-wide {
        transition: 0.5s;
        opacity: 0;
    }

    @media screen and (min-width: 951px){
        .pan-narrow:hover {
            object-position: top;
            opacity: 1;
            -webkit-animation: 20s SCROLLDOWN infinite; /* Safari 4+ */
            -moz-animation:    20s SCROLLDOWN infinite;/* Fx 5+ */
            -o-animation:      20s SCROLLDOWN infinite; /* Opera 12+ */
            animation:         20s SCROLLDOWN infinite; /* IE 10+, Fx 29+ */
        }

        .pan-wide:hover {
            object-position: top;
            opacity: 1;
            -webkit-animation: 20s SCROLLRIGHT infinite; /* Safari 4+ */
            -moz-animation:    20s SCROLLRIGHT infinite;/* Fx 5+ */
            -o-animation:      20s SCROLLRIGHT infinite; /* Opera 12+ */
            animation:         20s SCROLLRIGHT infinite; /* IE 10+, Fx 29+ */
        }
    }

    @media screen and (max-width: 950px){
        .preview {
            width: 100%;
            flex: 1;
            position: relative;
            padding-bottom: 0px;
        }
        
        .img-window {
            margin: 0rem;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }

    @-webkit-keyframes SCROLLDOWN {
        0%   { opacity: 0; object-position: top; }
        1%   { opacity: 1; object-position: top; }
        95% { opacity: 1; object-position: bottom;  }
        100% { opacity: 0; object-position: bottom; }
    }
    @-moz-keyframes SCROLLDOWN {
        0%   { opacity: 0; object-position: top; }
        1%   { opacity: 1; object-position: top; }
        95% { opacity: 1; object-position: bottom;  }
        100% { opacity: 0; object-position: bottom; }
    }
    @-o-keyframes SCROLLDOWN {
        0%   { opacity: 0; object-position: top; }
        1%   { opacity: 1; object-position: top; }
        95% { opacity: 1; object-position: bottom;  }
        100% { opacity: 0; object-position: bottom; }
    }

    @keyframes SCROLLDOWN {
        0%   { opacity: 0; object-position: top; }
        1%   { opacity: 1; object-position: top; }
        95% { opacity: 1; object-position: bottom;  }
        100% { opacity: 0; object-position: bottom; }
    }

    @-webkit-keyframes SCROLLRIGHT {
        0%   { opacity: 0; object-position: left; }
        1%   { opacity: 1; object-position: left; }
        95% { opacity: 1; object-position: right;  }
        100% { opacity: 0; object-position: right; }
    }
    @-moz-keyframes SCROLLRIGHT {
        0%   { opacity: 0; object-position: left; }
        1%   { opacity: 1; object-position: left; }
        95% { opacity: 1; object-position: right;  }
        100% { opacity: 0; object-position: right; }
    }
    @-o-keyframes SCROLLRIGHT {
        0%   { opacity: 0; object-position: left; }
        1%   { opacity: 1; object-position: left; }
        95% { opacity: 1; object-position: right;  }
        100% { opacity: 0; object-position: right; }
    }

    @keyframes SCROLLRIGHT {
        0%   { opacity: 0; object-position: left; }
        1%   { opacity: 1; object-position: left; }
        95% { opacity: 1; object-position: right;  }
        100% { opacity: 0; object-position: right; }
    }

    @media (prefers-reduced-motion) {
        .pan-narrow, .pan-wide {
            animation-name: dissolve;
        }
    }

</style>